import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import { Formik } from 'formik'
import styled from 'styled-components'
import { Link } from 'gatsby'
import * as Yup from 'yup'
import {
  Button,
  FormBox,
  FormFields,
  FormField,
  FormError,
  FormCopy,
} from '../page/components'

const userSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  password: Yup.string()
    .required('Please Enter your password')
    .test(
      'regex',
      'Password must be at least 8 characters, contain a symbol and a number character.',
      val => {
        let regExp = new RegExp('^(?=(.*\\d|.*?[#?!@$%^&*-]))(?=.*[a-z]).{8,}$')
        return regExp.test(val)
      }
    ),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Password confirm is required'),
})

const INITIAL_STATUS = {
  codeOk: false,
  codeError: null,
  email: '',
  submitting: false,
  submitted: false,
  submitErrors: [],
}

const ConfirmPassword = ({ firebase, actionCode }) => {
  // console.log('>>>', actionCode, continueUrl, lang)

  const [status, setStatus] = useState(INITIAL_STATUS)
  const { auth } = firebase

  useEffect(() => {
    auth
      .verifyPasswordResetCode(actionCode)
      .then(function(email) {
        setStatus(prevState => ({
          ...prevState,
          email: email,
          codeOk: true,
        }))
      })
      .catch(function(error) {
        const { message } = error
        setStatus(prevState => ({
          ...prevState,
          codeError: message,
        }))
      })
  }, [])

  const onSubmit = values => {
    if (submitting) return false

    console.log('??')

    const { password } = values

    setStatus(prevState => ({
      ...prevState,
      submitting: true,
      submitErrors: [],
    }))

    auth
      .confirmPasswordReset(actionCode, password)
      .then(function() {
        setStatus(prevState => ({
          ...prevState,
          submitting: false,
          submitted: true,
          submitErrors: [],
        }))
      })
      .catch(function(error) {
        const { message } = error

        setStatus(prevState => ({
          ...prevState,
          submitting: false,
          submitted: true,
          submitErrors: [message],
        }))
      })
  }

  let content = null
  const {
    submitted,
    submitting,
    codeError,
    codeOk,
    email,
    submitErrors,
  } = status

  if (codeError) {
    content = (
      <FormBox>
        <FormFields>
          <FormField width={`100%`}>
            <FormCopy>
              <p>{codeError}</p>
            </FormCopy>
          </FormField>
        </FormFields>
      </FormBox>
    )
  } else if (submitted) {
    content = (
      <FormBox>
        <FormFields>
          <FormField width={`100%`}>
            <FormCopy>
              <p>
                You password has been reset. Sign-in{' '}
                <Link to="/sign-in">here</Link>
              </p>
            </FormCopy>
          </FormField>
        </FormFields>
      </FormBox>
    )
  } else if (codeOk) {
    content = (
      <Form
        email={email}
        onSubmit={onSubmit}
        submitErrors={submitErrors}
        submitting={submitting}
      />
    )
  }

  return content
}

export default ConfirmPassword

const Form = ({ email, onSubmit, submitErrors, submitting }) => {
  console.log('email', email)

  let errorContent
  if (submitErrors.length > 0) {
    errorContent = (
      <FormField width="100%">
        <FormError>
          {submitErrors.map((error, i) => {
            return <div key={`error-${i}`}>{error}</div>
          })}
        </FormError>
      </FormField>
    )
  }

  const buttonText = submitting ? `Submitting...` : `Submit`

  return (
    <Formik
      initialValues={{
        email: email,
        password: '',
        passwordConfirm: '',
      }}
      validationSchema={userSchema}
      onSubmit={values => {
        // console.log('Form Submit', values)
        onSubmit(values)
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <FormBox processing={submitting}>
          <form onSubmit={handleSubmit}>
            <FormFields>
              <FormField width={`100%`}>
                <StyledTextField
                  label="Email"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  margin="normal"
                  disabled
                />
                {errors.email && touched.email ? (
                  <FormError>{errors.email}</FormError>
                ) : null}
              </FormField>
              <FormField>
                <StyledTextField
                  label="New Password"
                  name="password"
                  type="password"
                  onChange={e => {
                    handleChange(e)
                  }}
                  onBlur={handleBlur}
                  value={values.password}
                  margin="normal"
                />
                {errors.password && touched.password ? (
                  <FormError>{errors.password}</FormError>
                ) : null}
              </FormField>
              <FormField>
                <StyledTextField
                  label="New Password Confirm"
                  name="passwordConfirm"
                  type="password"
                  onChange={e => {
                    handleChange(e)
                  }}
                  onBlur={handleBlur}
                  value={values.passwordConfirm}
                  margin="normal"
                />
                {errors.passwordConfirm && touched.passwordConfirm ? (
                  <FormError>{errors.passwordConfirm}</FormError>
                ) : null}
              </FormField>
              {errorContent}
              <FormField marginRight={`50%`}>
                <Button type="submit">{buttonText}</Button>
              </FormField>
            </FormFields>
          </form>
        </FormBox>
      )}
    </Formik>
  )
}

const StyledTextField = styled(TextField)`
  ${props => props.theme.materialTextField()}
`
