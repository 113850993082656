import React from 'react'
import { withFirebase } from '../firebase'
import { Title } from '../page/components'
import ConfirmPassword from './confirm-password'
import FocusHeader from '../global/focus-header'
import FocusBody from '../page/focus-body'

const Confirm = ({ firebase, url }) => {
  if (!firebase) return null

  const urlObject = new URL(url)
  const mode = urlObject.searchParams.get('mode')
  const actionCode = urlObject.searchParams.get('oobCode')
  const continueUrl = urlObject.searchParams.get('continueUrl')
  const lang = urlObject.searchParams.get('lang') || 'en'

  const handleMode = mode => {
    switch (mode) {
      case 'resetPassword':
        return (
          <>
            <FocusHeader
              title="Account"
              subTitle="Password Reset"
              titleLink="/account"
              backUrl={'/account'}
            />
            <FocusBody>
              <Title>New Password</Title>
              <ConfirmPassword
                firebase={firebase}
                actionCode={actionCode}
                continueUrl={continueUrl}
                lang={lang}
              />
            </FocusBody>
          </>
        )
      default:
        return null
    }
  }
  return handleMode(mode)
}

export default withFirebase(Confirm)
