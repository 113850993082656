import React from 'react'
import { Wrap } from '../components/page/components'
import SEO from '../components/SEO'
import ConfirmBody from '../components/account/confirm'

const Confirm = ({ location }) => {
  const { href } = location
  return (
    <Wrap>
      <SEO title="Account" />
      <ConfirmBody url={href} />
    </Wrap>
  )
}

export default Confirm
